/*
|--------------------------------------------------------------------------
|  3.0 Mfn-icons
|
|--------------------------------------------------------------------------
|
|
|
*/

@font-face {
    font-family: 'mfn-icons';
    src: url(fonts/mfn-icons.eot);
    src: url(fonts/mfn-icons.eot) format("embedded-opentype"), url(fonts/mfn-icons.woff) format("woff"), url(fonts/mfn-icons.ttf) format("truetype"), url(fonts/mfn-icons.svg) format("svg");
    font-weight: 400;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'mfn-icons';
    src: url('fonts/../font/mfn-icons.svg?3416171#mfn-icons') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "mfn-icons";
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: none !important;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-acrobat:before {
    content: '\e800'
}
.icon-address:before {
    content: '\e801'
}
.icon-adjust:before {
    content: '\e802'
}
.icon-aim:before {
    content: '\e803'
}
.icon-air:before {
    content: '\e804'
}
.icon-alert:before {
    content: '\e805'
}
.icon-amazon:before {
    content: '\e806'
}
.icon-android:before {
    content: '\e807'
}
.icon-angellist:before {
    content: '\e808'
}
.icon-appnet:before {
    content: '\e809'
}
.icon-appstore:before {
    content: '\e80a'
}
.icon-archive:before {
    content: '\e80b'
}
.icon-arrow-combo:before {
    content: '\e80c'
}
.icon-arrows-ccw:before {
    content: '\e80d'
}
.icon-attach:before {
    content: '\e80e'
}
.icon-attach-line:before {
    content: '\e80f'
}
.icon-attention:before {
    content: '\e810'
}
.icon-back:before {
    content: '\e811'
}
.icon-back-in-time:before {
    content: '\e812'
}
.icon-bag:before {
    content: '\e813'
}
.icon-basket:before {
    content: '\e814'
}
.icon-battery:before {
    content: '\e815'
}
.icon-beaker-line:before {
    content: '\e816'
}
.icon-bell:before {
    content: '\e817'
}
.icon-bitbucket:before {
    content: '\e818'
}
.icon-bitcoin:before {
    content: '\e819'
}
.icon-block:before {
    content: '\e81a'
}
.icon-blogger:before {
    content: '\e81b'
}
.icon-book:before {
    content: '\e81c'
}
.icon-book-open:before {
    content: '\e81d'
}
.icon-bookmark:before {
    content: '\e81e'
}
.icon-bookmarks:before {
    content: '\e81f'
}
.icon-box:before {
    content: '\e820'
}
.icon-briefcase:before {
    content: '\e821'
}
.icon-brush:before {
    content: '\e822'
}
.icon-bucket:before {
    content: '\e823'
}
.icon-buffer:before {
    content: '\e824'
}
.icon-calendar:before {
    content: '\e825'
}
.icon-calendar-line:before {
    content: '\e826'
}
.icon-call:before {
    content: '\e827'
}
.icon-camera:before {
    content: '\e828'
}
.icon-camera-line:before {
    content: '\e829'
}
.icon-cancel:before {
    content: '\e82a'
}
.icon-cancel-circled:before {
    content: '\e82b'
}
.icon-cancel-squared:before {
    content: '\e82c'
}
.icon-cart:before {
    content: '\e82d'
}
.icon-cc:before {
    content: '\e82e'
}
.icon-cc-by:before {
    content: '\e82f'
}
.icon-cc-nc:before {
    content: '\e830'
}
.icon-cc-nc-eu:before {
    content: '\e831'
}
.icon-cc-nc-jp:before {
    content: '\e832'
}
.icon-cc-nd:before {
    content: '\e833'
}
.icon-cc-pd:before {
    content: '\e834'
}
.icon-cc-remix:before {
    content: '\e835'
}
.icon-cc-sa:before {
    content: '\e836'
}
.icon-cc-share:before {
    content: '\e837'
}
.icon-cc-zero:before {
    content: '\e838'
}
.icon-ccw:before {
    content: '\e839'
}
.icon-cd:before {
    content: '\e83a'
}
.icon-cd-line:before {
    content: '\e83b'
}
.icon-chart-area:before {
    content: '\e83c'
}
.icon-chart-bar:before {
    content: '\e83d'
}
.icon-chart-line:before {
    content: '\e83e'
}
.icon-chart-pie:before {
    content: '\e83f'
}
.icon-chat:before {
    content: '\e840'
}
.icon-check:before {
    content: '\e841'
}
.icon-clipboard:before {
    content: '\e842'
}
.icon-clock:before {
    content: '\e843'
}
.icon-clock-line:before {
    content: '\e844'
}
.icon-cloud:before {
    content: '\e845'
}
.icon-cloud-line:before {
    content: '\e846'
}
.icon-cloud-thunder:before {
    content: '\e847'
}
.icon-cloudapp:before {
    content: '\e848'
}
.icon-code:before {
    content: '\e849'
}
.icon-cog:before {
    content: '\e84a'
}
.icon-cog-line:before {
    content: '\e84b'
}
.icon-comment-fa:before {
    content: '\e84c'
}
.icon-comment-line:before {
    content: '\e84d'
}
.icon-compass:before {
    content: '\e84e'
}
.icon-credit-card:before {
    content: '\e84f'
}
.icon-cup:before {
    content: '\e850'
}
.icon-cup-line:before {
    content: '\e851'
}
.icon-cw:before {
    content: '\e852'
}
.icon-database-line:before {
    content: '\e853'
}
.icon-delicious:before {
    content: '\e854'
}
.icon-desktop-line:before {
    content: '\e855'
}
.icon-diamond-line:before {
    content: '\e856'
}
.icon-digg:before {
    content: '\e857'
}
.icon-direction:before {
    content: '\e858'
}
.icon-disqus:before {
    content: '\e859'
}
.icon-doc:before {
    content: '\e85a'
}
.icon-doc-landscape:before {
    content: '\e85b'
}
.icon-doc-line:before {
    content: '\e85c'
}
.icon-doc-text:before {
    content: '\e85d'
}
.icon-doc-text-inv:before {
    content: '\e85e'
}
.icon-docs:before {
    content: '\e85f'
}
.icon-dot:before {
    content: '\e860'
}
.icon-dot-2:before {
    content: '\e861'
}
.icon-dot-3:before {
    content: '\e862'
}
.icon-down:before {
    content: '\e863'
}
.icon-down-bold:before {
    content: '\e864'
}
.icon-down-circled:before {
    content: '\e865'
}
.icon-down-dir:before {
    content: '\e866'
}
.icon-down-open:before {
    content: '\e867'
}
.icon-down-open-big:before {
    content: '\e868'
}
.icon-down-open-mini:before {
    content: '\e869'
}
.icon-down-thin:before {
    content: '\e86a'
}
.icon-download:before {
    content: '\e86b'
}
.icon-drive:before {
    content: '\e86c'
}
.icon-droplet:before {
    content: '\e86d'
}
.icon-drupal:before {
    content: '\e86e'
}
.icon-duckduckgo:before {
    content: '\e86f'
}
.icon-dwolla:before {
    content: '\e870'
}
.icon-ebay:before {
    content: '\e871'
}
.icon-email:before {
    content: '\e872'
}
.icon-erase:before {
    content: '\e873'
}
.icon-eventasaurus:before {
    content: '\e874'
}
.icon-eventbrite:before {
    content: '\e875'
}
.icon-eventful:before {
    content: '\e876'
}
.icon-export:before {
    content: '\e877'
}
.icon-eye:before {
    content: '\e878'
}
.icon-eye-line:before {
    content: '\e879'
}
.icon-fast-backward:before {
    content: '\e87a'
}
.icon-fast-forward:before {
    content: '\e87b'
}
.icon-feather:before {
    content: '\e87c'
}
.icon-fire-line:before {
    content: '\e87d'
}
.icon-fivehundredpx:before {
    content: '\e87e'
}
.icon-flag:before {
    content: '\e87f'
}
.icon-flash:before {
    content: '\e880'
}
.icon-flashlight:before {
    content: '\e881'
}
.icon-flight:before {
    content: '\e882'
}
.icon-floppy:before {
    content: '\e883'
}
.icon-flow-branch:before {
    content: '\e884'
}
.icon-flow-cascade:before {
    content: '\e885'
}
.icon-flow-line:before {
    content: '\e886'
}
.icon-flow-parallel:before {
    content: '\e887'
}
.icon-flow-tree:before {
    content: '\e888'
}
.icon-folder:before {
    content: '\e889'
}
.icon-food-line:before {
    content: '\e88a'
}
.icon-forrst:before {
    content: '\e88b'
}
.icon-forward:before {
    content: '\e88c'
}
.icon-gauge:before {
    content: '\e88d'
}
.icon-globe:before {
    content: '\e88e'
}
.icon-globe-line:before {
    content: '\e88f'
}
.icon-gmail:before {
    content: '\e890'
}
.icon-googleplay:before {
    content: '\e891'
}
.icon-gowalla:before {
    content: '\e892'
}
.icon-graduation-cap:before {
    content: '\e893'
}
.icon-graduation-cap-line:before {
    content: '\e894'
}
.icon-grooveshark:before {
    content: '\e895'
}
.icon-guest:before {
    content: '\e896'
}
.icon-hackernews:before {
    content: '\e897'
}
.icon-heart-empty-fa:before {
    content: '\e898'
}
.icon-heart-fa:before {
    content: '\e899'
}
.icon-heart-line:before {
    content: '\e89a'
}
.icon-help:before {
    content: '\e89b'
}
.icon-help-circled:before {
    content: '\e89c'
}
.icon-home:before {
    content: '\e89d'
}
.icon-hourglass:before {
    content: '\e89e'
}
.icon-html5:before {
    content: '\e89f'
}
.icon-ie:before {
    content: '\e8a0'
}
.icon-inbox:before {
    content: '\e8a1'
}
.icon-inbox-line:before {
    content: '\e8a2'
}
.icon-infinity:before {
    content: '\e8a3'
}
.icon-info:before {
    content: '\e8a4'
}
.icon-info-circled:before {
    content: '\e8a5'
}
.icon-install:before {
    content: '\e8a6'
}
.icon-instapaper:before {
    content: '\e8a7'
}
.icon-intensedebate:before {
    content: '\e8a8'
}
.icon-itunes:before {
    content: '\e8a9'
}
.icon-key:before {
    content: '\e8aa'
}
.icon-key-line:before {
    content: '\e8ab'
}
.icon-keyboard:before {
    content: '\e8ac'
}
.icon-klout:before {
    content: '\e8ad'
}
.icon-lamp:before {
    content: '\e8ae'
}
.icon-language:before {
    content: '\e8af'
}
.icon-lanyrd:before {
    content: '\e8b0'
}
.icon-layout:before {
    content: '\e8b1'
}
.icon-leaf:before {
    content: '\e8b2'
}
.icon-left:before {
    content: '\e8b3'
}
.icon-left-bold:before {
    content: '\e8b4'
}
.icon-left-circled:before {
    content: '\e8b5'
}
.icon-left-dir:before {
    content: '\e8b6'
}
.icon-left-open:before {
    content: '\e8b7'
}
.icon-left-open-big:before {
    content: '\e8b8'
}
.icon-left-open-mini:before {
    content: '\e8b9'
}
.icon-left-thin:before {
    content: '\e8ba'
}
.icon-lego:before {
    content: '\e8bb'
}
.icon-level-down:before {
    content: '\e8bc'
}
.icon-level-up:before {
    content: '\e8bd'
}
.icon-lifebuoy:before {
    content: '\e8be'
}
.icon-light-down:before {
    content: '\e8bf'
}
.icon-light-up:before {
    content: '\e8c0'
}
.icon-lightbulb-line:before {
    content: '\e8c1'
}
.icon-link:before {
    content: '\e8c2'
}
.icon-list:before {
    content: '\e8c3'
}
.icon-list-add:before {
    content: '\e8c4'
}
.icon-lkdto:before {
    content: '\e8c5'
}
.icon-location:before {
    content: '\e8c6'
}
.icon-location-line:before {
    content: '\e8c7'
}
.icon-lock:before {
    content: '\e8c8'
}
.icon-lock-line:before {
    content: '\e8c9'
}
.icon-lock-open:before {
    content: '\e8ca'
}
.icon-login:before {
    content: '\e8cb'
}
.icon-logout:before {
    content: '\e8cc'
}
.icon-loop:before {
    content: '\e8cd'
}
.icon-macstore:before {
    content: '\e8ce'
}
.icon-magnet:before {
    content: '\e8cf'
}
.icon-mail:before {
    content: '\e8d0'
}
.icon-mail-line:before {
    content: '\e8d1'
}
.icon-map:before {
    content: '\e8d2'
}
.icon-meetup:before {
    content: '\e8d3'
}
.icon-megaphone:before {
    content: '\e8d4'
}
.icon-megaphone-line:before {
    content: '\e8d5'
}
.icon-menu:before {
    content: '\e8d6'
}
.icon-mic:before {
    content: '\e8d7'
}
.icon-minus:before {
    content: '\e8d8'
}
.icon-minus-circled:before {
    content: '\e8d9'
}
.icon-minus-squared:before {
    content: '\e8da'
}
.icon-mobile:before {
    content: '\e8db'
}
.icon-mobile-line:before {
    content: '\e8dc'
}
.icon-money-line:before {
    content: '\e8dd'
}
.icon-monitor:before {
    content: '\e8de'
}
.icon-moon:before {
    content: '\e8df'
}
.icon-mouse:before {
    content: '\e8e0'
}
.icon-music:before {
    content: '\e8e1'
}
.icon-music-line:before {
    content: '\e8e2'
}
.icon-mute:before {
    content: '\e8e3'
}
.icon-myspace:before {
    content: '\e8e4'
}
.icon-network:before {
    content: '\e8e5'
}
.icon-newspaper:before {
    content: '\e8e6'
}
.icon-ninetyninedesigns:before {
    content: '\e8e7'
}
.icon-note:before {
    content: '\e8e8'
}
.icon-note-beamed:before {
    content: '\e8e9'
}
.icon-note-line:before {
    content: '\e8ea'
}
.icon-openid:before {
    content: '\e8eb'
}
.icon-opentable:before {
    content: '\e8ec'
}
.icon-palette:before {
    content: '\e8ed'
}
.icon-paper-plane:before {
    content: '\e8ee'
}
.icon-paper-plane-line:before {
    content: '\e8ef'
}
.icon-params-line:before {
    content: '\e8f0'
}
.icon-pause:before {
    content: '\e8f1'
}
.icon-pencil:before {
    content: '\e8f2'
}
.icon-pencil-line:before {
    content: '\e8f3'
}
.icon-phone:before {
    content: '\e8f4'
}
.icon-photo-line:before {
    content: '\e8f5'
}
.icon-picture:before {
    content: '\e8f6'
}
.icon-pinboard:before {
    content: '\e8f7'
}
.icon-plancast:before {
    content: '\e8f8'
}
.icon-play:before {
    content: '\e8f9'
}
.icon-plurk:before {
    content: '\e8fa'
}
.icon-plus:before {
    content: '\e8fb'
}
.icon-plus-circled:before {
    content: '\e8fc'
}
.icon-plus-squared:before {
    content: '\e8fd'
}
.icon-pocket:before {
    content: '\e8fe'
}
.icon-podcast:before {
    content: '\e8ff'
}
.icon-popup:before {
    content: '\e900'
}
.icon-posterous:before {
    content: '\e901'
}
.icon-print:before {
    content: '\e902'
}
.icon-progress-0:before {
    content: '\e903'
}
.icon-progress-1:before {
    content: '\e904'
}
.icon-progress-2:before {
    content: '\e905'
}
.icon-progress-3:before {
    content: '\e906'
}
.icon-publish:before {
    content: '\e907'
}
.icon-quora:before {
    content: '\e908'
}
.icon-quote:before {
    content: '\e909'
}
.icon-record:before {
    content: '\e90a'
}
.icon-reddit:before {
    content: '\e90b'
}
.icon-reply:before {
    content: '\e90c'
}
.icon-reply-all:before {
    content: '\e90d'
}
.icon-resize-full:before {
    content: '\e90e'
}
.icon-resize-small:before {
    content: '\e90f'
}
.icon-retweet:before {
    content: '\e910'
}
.icon-right:before {
    content: '\e911'
}
.icon-right-bold:before {
    content: '\e912'
}
.icon-right-circled:before {
    content: '\e913'
}
.icon-right-dir:before {
    content: '\e914'
}
.icon-right-open:before {
    content: '\e915'
}
.icon-right-open-big:before {
    content: '\e916'
}
.icon-right-open-mini:before {
    content: '\e917'
}
.icon-right-thin:before {
    content: '\e918'
}
.icon-rocket:before {
    content: '\e919'
}
.icon-rss:before {
    content: '\e91a'
}
.icon-search:before {
    content: '\e91b'
}
.icon-search-line:before {
    content: '\e91c'
}
.icon-share:before {
    content: '\e91d'
}
.icon-shareable:before {
    content: '\e91e'
}
.icon-shop-line:before {
    content: '\e91f'
}
.icon-shuffle:before {
    content: '\e920'
}
.icon-signal:before {
    content: '\e921'
}
.icon-smashmag:before {
    content: '\e922'
}
.icon-songkick:before {
    content: '\e923'
}
.icon-sound:before {
    content: '\e924'
}
.icon-sound-line:before {
    content: '\e925'
}
.icon-stackoverflow:before {
    content: '\e926'
}
.icon-star:before {
    content: '\e927'
}
.icon-star-empty:before {
    content: '\e928'
}
.icon-star-line:before {
    content: '\e929'
}
.icon-statusnet:before {
    content: '\e92a'
}
.icon-stop:before {
    content: '\e92b'
}
.icon-suitcase:before {
    content: '\e92c'
}
.icon-switch:before {
    content: '\e92d'
}
.icon-t-shirt-line:before {
    content: '\e92e'
}
.icon-tag:before {
    content: '\e92f'
}
.icon-tag-line:before {
    content: '\e930'
}
.icon-tape:before {
    content: '\e931'
}
.icon-target:before {
    content: '\e932'
}
.icon-thermometer:before {
    content: '\e933'
}
.icon-thumbs-up:before {
    content: '\e934'
}
.icon-thumbs-down:before {
    content: '\e935'
}
.icon-thumbs-up-line:before {
    content: '\e936'
}
.icon-ticket:before {
    content: '\e937'
}
.icon-to-end:before {
    content: '\e938'
}
.icon-to-start:before {
    content: '\e939'
}
.icon-tools:before {
    content: '\e93a'
}
.icon-traffic-cone:before {
    content: '\e93b'
}
.icon-trash:before {
    content: '\e93c'
}
.icon-trash-line:before {
    content: '\e93d'
}
.icon-trophy:before {
    content: '\e93e'
}
.icon-truck-line:before {
    content: '\e93f'
}
.icon-tv-line:before {
    content: '\e940'
}
.icon-up:before {
    content: '\e941'
}
.icon-up-bold:before {
    content: '\e942'
}
.icon-up-circled:before {
    content: '\e943'
}
.icon-up-dir:before {
    content: '\e944'
}
.icon-up-open:before {
    content: '\e945'
}
.icon-up-open-big:before {
    content: '\e946'
}
.icon-up-open-mini:before {
    content: '\e947'
}
.icon-up-thin:before {
    content: '\e948'
}
.icon-upload:before {
    content: '\e949'
}
.icon-upload-cloud:before {
    content: '\e94a'
}
.icon-user:before {
    content: '\e94b'
}
.icon-user-add:before {
    content: '\e94c'
}
.icon-user-line:before {
    content: '\e94d'
}
.icon-users:before {
    content: '\e94e'
}
.icon-vcard:before {
    content: '\e94f'
}
.icon-viadeo:before {
    content: '\e950'
}
.icon-video:before {
    content: '\e951'
}
.icon-videocam-line:before {
    content: '\e952'
}
.icon-vk:before {
    content: '\e953'
}
.icon-volume:before {
    content: '\e954'
}
.icon-w3c:before {
    content: '\e955'
}
.icon-wallet-line:before {
    content: '\e956'
}
.icon-water:before {
    content: '\e957'
}
.icon-weibo:before {
    content: '\e958'
}
.icon-wikipedia:before {
    content: '\e959'
}
.icon-window:before {
    content: '\e95a'
}
.icon-wordpress:before {
    content: '\e95b'
}
.icon-xing:before {
    content: '\e95c'
}
.icon-yahoo:before {
    content: '\e95d'
}
.icon-yelp:before {
    content: '\e95e'
}
.icon-youtube:before {
    content: '\e95f'
}
.icon-menu-fine:before {
    content: '\e960'
}
.icon-bag-fine:before {
    content: '\e961'
}
.icon-search-fine:before {
    content: '\e962'
}
.icon-cancel-fine:before {
    content: '\e963'
}
.icon-plus-fine:before {
    content: '\e964'
}
.icon-minus-fine:before {
    content: '\e965'
}
.icon-gplus-circled:before {
    content: '\f059'
}
.icon-github-circled:before {
    content: '\f09b'
}
.icon-gplus:before {
    content: '\f0d5'
}
.icon-comment-empty-fa:before {
    content: '\f0e5'
}
.icon-instagram:before {
    content: '\f16d'
}
.icon-tumblr:before {
    content: '\f173'
}
.icon-windows:before {
    content: '\f17a'
}
.icon-foursquare:before {
    content: '\f180'
}
.icon-google:before {
    content: '\f1a0'
}
.icon-behance:before {
    content: '\f1b4'
}
.icon-steam:before {
    content: '\f1b6'
}
.icon-spotify:before {
    content: '\f1bc'
}
.icon-database:before {
    content: '\f1c0'
}
.icon-qq:before {
    content: '\f1d6'
}
.icon-paypal:before {
    content: '\f1ed'
}
.icon-stripe:before {
    content: '\f1f5'
}
.icon-chrome:before {
    content: '\f268'
}
.icon-scribd:before {
    content: '\f28a'
}
.icon-github:before {
    content: '\f300'
}
.icon-flickr:before {
    content: '\f303'
}
.icon-flickr-circled:before {
    content: '\f304'
}
.icon-vimeo:before {
    content: '\f306'
}
.icon-vimeo-circled:before {
    content: '\f307'
}
.icon-twitter:before {
    content: '\f309'
}
.icon-twitter-circled:before {
    content: '\f30a'
}
.icon-facebook:before {
    content: '\f30c'
}
.icon-facebook-circled:before {
    content: '\f30d'
}
.icon-facebook-squared:before {
    content: '\f30e'
}
.icon-pinterest:before {
    content: '\f312'
}
.icon-pinterest-circled:before {
    content: '\f313'
}
.icon-tumblr-circled:before {
    content: '\f316'
}
.icon-linkedin:before {
    content: '\f318'
}
.icon-linkedin-circled:before {
    content: '\f319'
}
.icon-dribbble:before {
    content: '\f31b'
}
.icon-dribbble-circled:before {
    content: '\f31c'
}
.icon-stumbleupon:before {
    content: '\f31e'
}
.icon-stumbleupon-circled:before {
    content: '\f31f'
}
.icon-lastfm:before {
    content: '\f321'
}
.icon-lastfm-circled:before {
    content: '\f322'
}
.icon-rdio:before {
    content: '\f324'
}
.icon-rdio-circled:before {
    content: '\f325'
}
.icon-spotify-circled:before {
    content: '\f328'
}
.icon-dropbox:before {
    content: '\f330'
}
.icon-evernote:before {
    content: '\f333'
}
.icon-flattr:before {
    content: '\f336'
}
.icon-skype:before {
    content: '\f339'
}
.icon-skype-circled:before {
    content: '\f33a'
}
.icon-renren:before {
    content: '\f33c'
}
.icon-sina-weibo:before {
    content: '\f33f'
}
.icon-picasa:before {
    content: '\f345'
}
.icon-soundcloud:before {
    content: '\f348'
}
.icon-mixi:before {
    content: '\f34b'
}
.icon-google-circles:before {
    content: '\f351'
}
.icon-vkontakte:before {
    content: '\f354'
}
.icon-smashing:before {
    content: '\f357'
}
.icon-db-shape:before {
    content: '\f600'
}
.icon-sweden:before {
    content: '\f601'
}
.icon-logo-db:before {
    content: '\f603'
}